import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 2.8rem;
  overflow: hidden;

  .slick-slider {
    display: flex;
  }
`;
