import styled, { css } from "styled-components";

interface ActiveTestimonalContainer {
  active: boolean;
}

export const TestimonalContainer = styled.div<ActiveTestimonalContainer>`
  position: relative;
  display: ${({ active }) => (active ? "block" : "none")};
`;

export const ArrowButton = css`
  border: 0.1rem solid ${({ theme }) => theme.colors.black["200"]};
  border-radius: 50%;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.04);
`;

export const PrevRoot = styled.div`
  ${ArrowButton}

  position: absolute;
  top: 15px;
  left: -125px;
  ${({ theme }) => theme.mediaQueries.maxSm} {
    display: none;
  }
`;

export const NextRoot = styled.div`
  ${ArrowButton}

  position: absolute;
  right: -125px;
  top: 15px;
  ${({ theme }) => theme.mediaQueries.maxSm} {
    display: none;
  }
`;
